@charset "UTF-8";
@import "./../../styles/vars";

.container{
  overflow: hidden;
  border-radius: 4px;
  background: rgba($p_colors_3, .3);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  transform-origin: 50% 50%; 
  animation: zoom $as;

  h1{
    position: relative; z-index: 500; color: #fff;
  }
}


.player{
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  bottom: 0;
  width: 100%;  
  overflow: hidden;
  transform: translateY(-50%);
  animation: opacity $as;
}


/* Controls */

.controlsContainer {
  background: rgba($p_colors_2, .8);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 20;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  color: $p_colors_4;
  opacity: 0;
  transition: opacity $as $ae;
}

.container_showPlayer{
  .controlsContainer{
    opacity: 1;
    //visibility: hidden;
  }
}

.controls {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: auto;
}

.controlsIcon, 
.controlsIcon_small {
  border-radius: 100%;
  background: $p_colors_4;
  color: $s_colors_3;
  display: flex;
  transition: all calc($as / 2) $ae;

  svg{
    animation: zoom $as;
    animation-delay: calc($as / 2);
    animation-fill-mode: both;
  }
}

.controlsIcon:active, 
.controlsIcon_small:active{
  background: $p_colors_3;
  color: $p_colors_4;
}

.controlsIcon,
.controlsIcon_small{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.controlsIcon {
  width: vh(160);
  height: vh(160);

  svg{
    width: 41.25%;
    height: 41.25%;
  }
}

.controlsIcon_small {
  width: vh(88);
  height: vh(88);
  top: 75%;

  svg{
    width: 100%;
    height: 100%;
  }
}

/* time controls */

.timecontrols {
  /* display: none; */
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  bottom: vw(32);
  left: vw(32);
  right: vw(32);
  z-index: 30;
  opacity: 0;
  transition: opacity $as $ae;
}

.container_showPlayer{
  .timecontrols{
    opacity: 1;
    //visibility: hidden;
  }
}

.time_progressbarContainer {
  background-color: $p_colors_3;
  border-radius: 15px;
  /* width: 75vw; */
  flex-grow: 6;
  height: vw(8);
  z-index: 30;
  position: relative;
  margin: 0 vw(32);
}

.time_progressBar {
  border-radius: 15px;
  background-color: $p_colors_4;
  height: 100%;
  /* transition: width 1s linear; */
  /* transition: width $as linear; */
}

.controlsTime {
  color: $p_colors_4;
  width: 3em;
  font-variant-numeric: tabular-nums;

  &:first-child{
    text-align: right;
  }
  &:last-child{
    text-align: left;
  }

}
