@charset "UTF-8";
@import "./../../styles/vars";

.wrapper{
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: flex-start;
}

.left,
.right{
  left: 0;
  position: relative;
  transition: all $as $ae;
}

.right{
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding: 0 vw(35);
}


.logoLink{
  display: inline-block;
  padding: vw(35);
  position: relative;
  font-size: 0;
  line-height: 0;
  color: $p_colors_2;
}

.logo{
  color: $p_colors_2;
  width: vw(304);
  height: vw(65.1);
}

.wrapper_home{
  .left{
    left: vw(-35);
  }

  .right{
    left: vw(35);
    opacity: 0;
    visibility: hidden;
  }
}


.link{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding: vw(37) vw(32) vw(43);
  position: relative;
  color: $p_colors_1;
  text-decoration: none;
  text-transform: uppercase;
  font-size: rem(20);
  line-height: vw(56);
  font-weight: 700;
  letter-spacing: 0.045em;
  white-space: nowrap;
  transition: color $as $ae;

  .icon{
    width: vw(56);
    height: vw(56);
    margin: 0 vw(22) 0 0;
    color: $p_colors_3;
    transition: all $as $ae;
  }

  &::after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    right: 50%;
    height: vw(6);
    background: transparent;
    transition: all $as $ae;
  }

}

.activeLink{
  color: $s_colors_3;

  .icon{
    transform-origin: 50% 50%;
    color: $s_colors_3;
    animation: itcrashes $as;
    
  }

  &::after{
    left: 7%;
    right: 7%;
    background: $s_colors_3;
  }

}

.linkText{
  text-decoration: none;
}


$rotateActive: 5;
@keyframes itcrashes {
  0%  { transform: scale(1) rotate(0deg); }
  30% { transform: scale(1.1) rotate($rotateActive * 1deg); }
  50% { transform: scale(1) rotate($rotateActive * -1deg); }
  70% { transform: scale(1.1) rotate($rotateActive * 1deg); }
  100% { transform: scale(1) rotate(0deg); }
}