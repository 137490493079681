@charset "UTF-8";
@import "./../../styles/vars";

.wrapper{
  display: flex;
  position: relative;
  z-index: 100;
  border-radius: 4px;
  background: $p_colors_4;
  box-shadow: 0 7px 10px 0 rgba(212, 222, 232, 0.30);
  transition: all $as $ae;
  
  &.wrapper_home{
    background: transparent;
    box-shadow: 0 0 0 0 rgba(212, 222, 232, 0);
  }
}
