@charset "UTF-8";
@import "./../../styles/vars";

.layout{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  min-height: 100vh;
  padding: vw(32);
}

.screen{
  background-image: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
  background-size: 100% auto;
  background-position: 50% 0;
  background-repeat: no-repeat;
  opacity: 0.5;

  &_active{
    background-image: url("../../../public/screenshots/02_Игровой\ режим.png");
  }

}

.screen_button{
  position: fixed;
  z-index: 3000;
  left: vw(16);
  bottom: vw(16);
  background: $s_colors_4;
  padding: vw(10) vw(20);
  border: 0 none;
  cursor: pointer;

  &:hover{
    background: $s_colors_1;
  }

}
