@charset "UTF-8";
@import "./../../styles/vars";

.container{
  position: absolute;
  left: 0;
  top: vw(16);
  max-width: vw(1100);
  z-index: 100;
  padding: vw(26) vw(32) vw(32);
  font-weight: 700;
  border-radius: vw(16);
  background: $p_colors_2;
  color: $p_colors_4;
  animation: zoom $as;
}

.stage{
  font-size: rem(20);
  text-transform: uppercase;
  color: rgba($p_colors_4, 0.6);
  margin: 0 0 vw(20);
  letter-spacing: 0.025em;
}

.text{
  font-size: rem(32);
  line-height: 1.25;
  font-weight: 700;
  letter-spacing: -0.01em;
}

.tail{
  width: vw(67);
  position: absolute;
  left: vw(275);
  top: 100%;
  font-size: 0;
  color: $p_colors_2;
  
  svg{
    width: 100%;
    height: auto;
  }
}
