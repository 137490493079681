@charset "UTF-8";
@import "./../../styles/vars";


.links{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  margin: 0 0 vw(160);
}

.link{
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: vw(375);
  //padding: vw(100);
  font-size: rem(32);
  line-height: 1.25;
  font-weight: 700;
  color: $p_colors_3;
  white-space: nowrap;
  margin: vw(-10) 0 0;
  padding: vw(50) vw(30);
  transform-origin: 50% 50%; 
  animation: zoomMax $as;
  animation-delay: calc($as / 2);
  animation-fill-mode: both;

  &:nth-child(1){
    animation-delay: calc($as / 8);
    svg{
      animation-delay: calc($as * 3);
    }
  }
  &:nth-child(1){
    animation-delay: calc($as / 6);
    svg{
      animation-delay: calc($as * 6);
    }
  }
  &:nth-child(2){
    animation-delay: calc($as / 4);
    svg{
      animation-delay: calc($as * 9);
    }
  }
  &:nth-child(3){
    animation-delay: calc($as / 2);
    svg{
      animation-delay: calc($as * 12);
    }
  }

  &:active{
    opacity: 0.9 !important;
    animation-delay: calc($as / 2) !important;
  }

}

.icon{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: vw(168);
  height: vw(168);
  border-radius: vw(16);
  background: $s_colors_1;
  margin: 0 0 vw(42);
  color: $p_colors_4;
  position: relative;
  /* animation: alarmСlockZoom calc($as * 16) linear infinite; */

  &::after{
    content: "";
    position: absolute;
    left: 0; top: 20px;
    width: vw(168);
    height: vw(168);
    filter: blur(14px);
    opacity: 0.3;
    background: $s_colors_1;
  }

  &[data-color="color-1"]{
    background: $s_colors_1;
    &::after{
      background: $s_colors_1;
    }
  }
  &[data-color="color-2"]{
    background: $s_colors_2;
    &::after{
      background: $s_colors_2;
    }
  }
  &[data-color="color-3"]{
    background: $s_colors_3;
    &::after{
      background: $s_colors_3;
    }
  }
  &[data-color="color-4"]{
    background: $s_colors_4;
    &::after{
      background: $s_colors_4;
    }
  }

  svg{
    width: vw(80);
    height: vw(80);
    position: relative;
    z-index: 3;
    animation: alarmСlock calc($as * 16) linear infinite;
  }
}