@charset "UTF-8";
@import "./../styles/vars";

.container {
  z-index: 2;
}

.video__list {
  overflow: hidden;
  position: absolute;
  left: vw(-60);
  top: vw(230);
  width: vw(500);
  /* 632 */
  height: vw(546);
  /* 690 */
  /* ratio - 1,264 */
  font-size: 0;
  animation: zoom $as;
  z-index: 1;
}

.video {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;

  &[data-active="1"] {
    opacity: 1;
  }

  &[data-active="0"] {
    opacity: 0;
  }
}


.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  transform-origin: 50% 50%;
  animation: zoom $as;

  video {
    width: 100%;
  }

}

.mainvideo {
  opacity: 0;
  visibility: hidden;
  transition: all $as*2 $ae;

  &_active {
    opacity: 1;
    visibility: visible;
  }
}

.control {
  position: fixed;
  right: vw(32);
  bottom: vw(32);
  background: $p_colors_2;
  color: $p_colors_4;
  padding: vw(8);
  text-transform: uppercase;
  font-weight: 700;
  font-size: rem(20);
  line-height: 2;
  border-radius: vw(16);
  opacity: 0;
  visibility: hidden;
  transition: all $as*2 $ae;

  &_active {
    opacity: 1;
    visibility: visible;
  }

  label {
    display: inline-block;
    padding: vw(12) vw(12);
    cursor: pointer;
    min-width: vw(160);
    white-space: nowrap;
    text-align: center;

    &:active {
      opacity: 0.9;
    }

    &.disable {
      color: rgba($p_colors_4, 0.3);
      cursor: no-drop;
    }

  }

  svg {
    width: vw(54);
    height: vw(54);
    vertical-align: middle;
    margin: vw(-10) 0 vw(-4);
  }

  .back {
    background: $s_colors_3;
    border-top-left-radius: vw(8);
    border-bottom-left-radius: vw(8);
  }

  .next {
    background: $s_colors_2;
    border-top-right-radius: vw(8);
    border-bottom-right-radius: vw(8);
  }

  .replay {
    text-align: left;
    width: vw(265);
  }

}


.linksCol {
  /* width: 60%; */
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 15% 0 0 7%;
}

.links {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  margin: 0 0 vw(160);
  cursor: pointer;
}

.link {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: vw(375);
  //padding: vw(100);
  font-size: rem(32);
  line-height: 1.25;
  font-weight: 700;
  color: $p_colors_3;
  white-space: nowrap;
  margin: vw(-10) 0 0;
  padding: vw(50) vw(30);
  transform-origin: 50% 50%;
  animation: zoomMax $as;
  animation-delay: calc($as / 2);
  animation-fill-mode: both;

  &:nth-child(1) {
    animation-delay: calc($as / 8);

    svg {
      animation-delay: calc($as * 3);
    }
  }

  &:nth-child(1) {
    animation-delay: calc($as / 6);

    svg {
      animation-delay: calc($as * 6);
    }
  }

  &:nth-child(2) {
    animation-delay: calc($as / 4);

    svg {
      animation-delay: calc($as * 9);
    }
  }

  &:nth-child(3) {
    animation-delay: calc($as / 2);

    svg {
      animation-delay: calc($as * 12);
    }
  }

  &:active {
    opacity: 0.9 !important;
    animation-delay: calc($as / 2) !important;
  }

}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: vw(168);
  height: vw(168);
  border-radius: vw(16);
  background: $s_colors_1;
  margin: 0 0 vw(42);
  color: $p_colors_4;
  position: relative;
  /* animation: alarmСlockZoom calc($as * 16) linear infinite; */

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 20px;
    width: vw(168);
    height: vw(168);
    filter: blur(14px);
    opacity: 0.3;
    background: $s_colors_1;
  }

  &[data-color="color-1"] {
    background: $s_colors_1;

    &::after {
      background: $s_colors_1;
    }
  }

  &[data-color="color-2"] {
    background: $s_colors_2;

    &::after {
      background: $s_colors_2;
    }
  }

  &[data-color="color-3"] {
    background: $s_colors_3;

    &::after {
      background: $s_colors_3;
    }
  }

  &[data-color="color-4"] {
    background: $s_colors_4;

    &::after {
      background: $s_colors_4;
    }
  }

  svg {
    width: vw(80);
    height: vw(80);
    position: relative;
    z-index: 3;
    animation: alarmСlock calc($as * 16) linear infinite;
  }
}