@charset "UTF-8";
@import "./vars";

html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: calc($layout-font-size / $layout-width) * 100vw;
  color: $p_colors_1;
  overflow: hidden;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

*, 
*:before, 
*:after {box-sizing: border-box;}

a{text-decoration: none;}
