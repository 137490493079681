@charset "UTF-8";
@import "./../styles/vars";

.container{
  display: flex;
  z-index: 1;
}

.videoCol{
  width: 28.5%;
  transform-origin: 50% 50%; 
  animation: zoomMax $as;
}

.video{
  width: vw(700);
  height: vw(800);
  position: relative;
  top: -2.5vw;
  left: 0.5vw;
}

.linksCol{
  /* width: 60%; */
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.header{
  font-size: rem(56);
  font-weight: 700;
  color: $p_colors_1;
  margin: 0 0 vw(50);
  animation: zoomMax $as;
}
