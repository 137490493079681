@charset "UTF-8";

/* vars */

$animation-speed: 0.35s;
$animation-easing: ease-in-out;

$layout-font-size: 20;
$layout-width: 1920;
$layout-height: 1080;

$primary-colors: (
  color_1: #27282E,
  color_2: #334190,
  color_3: #939AC4,
  color_4: #ffffff,
);

$secondary-colors: (
  color_1: #E8417D,
  color_2: #F3885B,
  color_3: #2E52D1,
  color_4: #47AB5D,
);


/* short vars */

$as: $animation-speed;
$ae: $animation-easing;

$fs: $layout-font-size;
$w: $layout-width;
$h: $layout-height;

$p_colors_1: map-get($primary-colors, color_1);
$p_colors_2: map-get($primary-colors, color_2);
$p_colors_3: map-get($primary-colors, color_3);
$p_colors_4: map-get($primary-colors, color_4);

$s_colors_1: map-get($secondary-colors, color_1);
$s_colors_2: map-get($secondary-colors, color_2);
$s_colors_3: map-get($secondary-colors, color_3);
$s_colors_4: map-get($secondary-colors, color_4);

/* function  */

@function rem ($font-size) {
  @return calc($font-size / $layout-font-size) * 1rem;
}

@function vw ($width) {
  @return calc($width / $layout-width) * 100vw;
}

@function vh ($height) {
  @return calc($height / $layout-height) * 100vh;
}


@keyframes zoom {
  0%  { 
    transform: scale(0.85);
    opacity: 0; 
  }
  50%  { 
    transform: scale(1.02);
    opacity: 1;
  }
  100% { 
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes zoomMax {
  0%  { 
    transform: scale(0.85);
    opacity: 0; 
  }
  50%  { 
    transform: scale(1.1);
    opacity: 1;
  }
  100% { 
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes opacity {
  0%  { 
    opacity: 0; 
  }
  50%  { 
    opacity: 0;
  }
  100% { 
    opacity: 1;
  }
}

$clock: 8;
@keyframes alarmСlock {
  0%, 47%, 53%, 100%  { transform: scale(1) rotate(0deg); }
  48%  { transform: scale(1.15) rotate($clock * -1deg); }
  49%  { transform: scale(1.1) rotate($clock * 1deg); }
  50%  { transform: scale(1.15) rotate($clock * -1deg); }
  51%  { transform: scale(1.1) rotate($clock * 1deg); }
  52%  { transform: scale(1.15) rotate($clock * -1deg); }
}
