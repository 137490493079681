@charset "UTF-8";
@import "./../../styles/vars";

.list{
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 0;

  .main,
  .inner,
  .inner_game{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    transition: all $as $ae;
  }

  .main{
    background: url("./../../images/MainBackground.svg") no-repeat 50% 50%;
    background-size: cover;
  }

  .inner{
    background: url("./../../images/InnerBackground.svg") no-repeat 50% 50%;
    background-size: cover;
  }

  .inner_game{
    background: url("./../../images/InnerBackgroundGame.svg") no-repeat 50% 50%;
    background-size: cover;
  }
  

  &.list_inner{
    .main{
      opacity: 0;
    }
  }

  &.list_inner_game{
    .main,
    .inner{
      opacity: 0;
    }
  }

}

.MainBackground{
  height: 100vh;
  width: 100vw;

}