@charset "UTF-8";
@import "./../styles/vars";

.container {
  z-index: 2;
}

.video__list {
  overflow: hidden;
  position: absolute;
  left: vw(-60);
  top: vw(230);
  width: vw(500);
  /* 632 */
  height: vw(546);
  /* 690 */
  /* ratio - 1,264 */
  font-size: 0;
  animation: zoom $as;
  z-index: 1;
}

.video {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;

  &[data-active="1"] {
    opacity: 1;
  }

  &[data-active="0"] {
    opacity: 0;
  }
}


.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  transform-origin: 50% 50%;
  animation: zoom $as;

  video {
    width: 100%;
  }

}


.game {
  background: $p_colors_4;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  position: absolute;
  right: vw(0);
  top: vw(16);
  /* bottom: vw(72); */
  width: vw(603);
  border-radius: vw(4);
  z-index: 3;

  opacity: 0;
  visibility: hidden;
  transition: all $as*2 $ae;

  &_active {
    opacity: 1;
    visibility: visible;
  }
}

.game__list {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-around;
  align-items: center;

}

.game__item {
  width: vw(278);
  height: vh(312);
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  border-radius: vw(4);
  border: 2px dashed rgba(58, 72, 152, 0.20);
  /* background: #EDF1FB; */
  margin: vw(15) 0 0;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  background-color: #EDF1FB;
  transition: all $as $ae;

  &:active {
    background-color: #E4EAF8;
  }

  &_help {
    animation: glowing 1300ms infinite;
  }

  &.game__item_true,
  &.game__item_false {
    border-style: solid;
    cursor: default;

    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      width: vw(48);
      height: vw(48);
      line-height: vw(48);
      text-align: center;
      border-bottom-left-radius: vw(4);
      background-repeat: no-repeat;
      background-position: center;
      padding: 8px;
    }

  }

  &.game__item_true {
    background-color: rgba($s_colors_4, 0.08);
    border-color: $s_colors_4;

    &::after {
      background-color: $s_colors_4;
      background-image: url('../images/game/ic_check.svg');
    }

  }

  &.game__item_false {
    background-color: rgba($s_colors_1, 0.08);
    border-color: $s_colors_1;

    &::after {
      background-color: $s_colors_1;
      background-image: url('../images/game/ic_close.svg');

    }

  }

  &.game__item_disabled {
    cursor: not-allowed;
  }

}

.game__item__img{

  min-height: 50%;

  img{
    max-width: 100%;
  }
}

@keyframes glowing {
  0% {
    border: 2px dashed rgba(58, 72, 152, 0.20);
  }

  50% {
    border: 2px dashed $s_colors_4;
  }

  100% {
    border: 2px dashed rgba(58, 72, 152, 0.20);
  }
}


.game__info {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: flex-start;
  align-items: flex-start;
  padding: vw(20) vw(16) vw(20) vw(56);
  position: relative;

  font-size: rem(20);
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;
  color: rgba($p_colors_2, 0.6);

  &::before {
    content: '';
    position: absolute;
    top: vw(22);
    left: vw(16);
    background-image: url('../images/game/info.svg');
    background-position: center;
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
  }

}

.game__item__name {
  font-size: rem(24);
  line-height: rem(32);
  font-weight: 700;
  margin: 0 0 vw(22);
}

.mainimg {
  position: relative;
  width: 1150px;
  height: 1080px;
  margin: 150px 367px 0 0;
  opacity: 0;
  visibility: hidden;
  transition: all $as*2 $ae;
  transition-delay: 3ms;

  &_active {
    margin: 237px 367px 0 0;
    opacity: 1;
    visibility: visible;
  }
}

.mainimg__foundation {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;

  img {
    width: 100%;
    height: 100%;
  }
}

.mainimg__foundation_shadowfloow {
  z-index: 1;
}

.mainimg__foundation_shadowinside {
  z-index: 3;
}

.mainimg__foundation_apparatus {
  z-index: 4;
  opacity: 1;
  visibility: visible;
  transition: all $as $ae;
  transform: scale(1);

  &_hidden {
    opacity: 0;
    visibility: hidden;
    top: -50px;
  }

  &_bounce_opacity {
    transform: scale(0.8);
    opacity: 0;
    visibility: hidden;
  }

  &_bounce {
   animation: bounce $as*2;
  }
}


@keyframes bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

.mainimg__foundation_front {
  z-index: 5;
}

.linksCol {
  /* width: 60%; */
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 15% 0 0 7%;
}

.links {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  margin: 0 0 vw(160);
  cursor: pointer;
}

.link {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: vw(375);
  //padding: vw(100);
  font-size: rem(32);
  line-height: 1.25;
  font-weight: 700;
  color: $p_colors_3;
  white-space: nowrap;
  margin: vw(-10) 0 0;
  padding: vw(50) vw(30);
  transform-origin: 50% 50%;
  animation: zoomMax $as;
  animation-delay: calc($as / 2);
  animation-fill-mode: both;

  &:nth-child(1) {
    animation-delay: calc($as / 8);

    svg {
      animation-delay: calc($as * 3);
    }
  }

  &:nth-child(1) {
    animation-delay: calc($as / 6);

    svg {
      animation-delay: calc($as * 6);
    }
  }

  &:nth-child(2) {
    animation-delay: calc($as / 4);

    svg {
      animation-delay: calc($as * 9);
    }
  }

  &:nth-child(3) {
    animation-delay: calc($as / 2);

    svg {
      animation-delay: calc($as * 12);
    }
  }

  &:active {
    opacity: 0.9 !important;
    animation-delay: calc($as / 2) !important;
  }

}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: vw(168);
  height: vw(168);
  border-radius: vw(16);
  background: $s_colors_1;
  margin: 0 0 vw(42);
  color: $p_colors_4;
  position: relative;
  /* animation: alarmСlockZoom calc($as * 16) linear infinite; */

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 20px;
    width: vw(168);
    height: vw(168);
    filter: blur(14px);
    opacity: 0.3;
    background: $s_colors_1;
  }

  &[data-color="color-1"] {
    background: $s_colors_1;

    &::after {
      background: $s_colors_1;
    }
  }

  &[data-color="color-2"] {
    background: $s_colors_2;

    &::after {
      background: $s_colors_2;
    }
  }

  &[data-color="color-3"] {
    background: $s_colors_3;

    &::after {
      background: $s_colors_3;
    }
  }

  &[data-color="color-4"] {
    background: $s_colors_4;

    &::after {
      background: $s_colors_4;
    }
  }

  svg {
    width: vw(80);
    height: vw(80);
    position: relative;
    z-index: 3;
    animation: alarmСlock calc($as * 16) linear infinite;
  }
}

.text_desc {
  position: absolute;
  top: 240px;
  right: 542px;
  z-index: 7;
  opacity: 0;
  visibility: hidden;
  transition: all $as*2 $ae;
  transition-delay: 2ms;

  &_active {
    right: 700px;
    opacity: 1;
    visibility: visible;
  }
}

.text_desc__item {
  margin-bottom: 35px;
}

.text_desc__item_img {
  margin-bottom: 13px;
}

.text_desc__item_title {
  font-size: rem(20);
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;
  color: rgba($color: $p_colors_1, $alpha: .6);
  margin-bottom: 2px;
}

.text_desc__item_text {
  font-size: rem(24);
  font-style: normal;
  font-weight: 700;
  line-height: 1.33;
  color: $p_colors_1;
}

.dragging {
  opacity: .25;
}

.cloneTouched {
  position: fixed;
  z-index: 100;
  transition: unset;
}

.hover {
  background-color: rgba(0,191,165,.04);
}

.drap_drop_container {
  position: absolute;
  top: 26%;
  left: 14%;
  z-index: 10;
  width: 50%;
  height: 70vh;
}