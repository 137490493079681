@charset "UTF-8";
@import "./../../styles/vars";

.content{
  position: relative;
  z-index: 10;
  flex-grow: 6;
  margin: vw(32) 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
